<template>
    <div class="c-app flex-row align-items-center" :style="{backgroundImage: 'url('+organization_background+')', backgroundSize: 'cover','box-shadow': 'inset 0 0 0 1000px rgba(108, 122, 137, 0.35)' }" >
        <CContainer >
            <CRow class="justify-content-center">
                <CCol md="4">
                    <center>
                        <font-awesome-icon icon="spinner" pulse size="6x" v-if="!default_password"/>
                    </center>
                    <CCard class="p-4 login-panel" v-if="default_password">
                        <CForm @submit.prevent="changeDefaultPassword">
                            <center>
                                <img :src="organization_logo">
                            </center>
                            <br>
                            <h4>Default Password Detected.</h4>
                            <p class="text-muted">Please update user password.</p>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.dp.old_password.$model"
                                :isValid="checkIfValidPassword('old_password')"
                                placeholder="Old Password" 
                                autocomplete="off" 
                                type="password"
                                v-model="dp.old_password"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                            </CInput>
                            <hr>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.dp.new_password.$model"
                                :isValid="checkIfValidPassword('new_password')"
                                placeholder="New Password" 
                                autocomplete="off" 
                                :type="password_text" 
                                v-model="dp.new_password"
                                invalidFeedback="Required password containing at least: number, uppercase and lowercase letter, 8 characters"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                                <template #append>
                                    <CButton
                                        color="primary"
                                        @click="password_view = password_view === false ? true : false, showPassword()"
                                    >
                                        <font-awesome-icon :icon="!password_view ? 'eye-slash' : 'eye'"/>
                                    </CButton>
                                </template>
                            </CInput>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.dp.new_confirm_password.$model"
                                :isValid="checkIfValidPassword('new_confirm_password')"
                                placeholder="Confirm New Password" 
                                autocomplete="off" 
                                :type="password_text" 
                                v-model="dp.new_confirm_password"
                                invalidFeedback="New and confirm password must match"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                            </CInput>
                            <CRow>
                                <CCol col="7" class="text-left" >
                                    <CButton 
                                        :disabled="!isValidPassword"
                                        type="submit" 
                                        color="primary" 
                                    >
                                        <font-awesome-icon icon="redo-alt"/> Update password
                                    </CButton>
                                </CCol>
                                <CCol col="5">
                                    <CButton 
                                        class="float-right"
                                        type="submit" 
                                        color="danger" 
                                        @click="default_password = !default_password"
                                    >
                                        <font-awesome-icon icon="window-close"/> Cancel
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
import { required, email, sameAs, minLength, helpers } from "vuelidate/lib/validators"
export default {
    name: 'BlankPageLogin',
    data() {
        return {
            password_text: 'password',
            password_view: false,
            organization_key: null,
            organization_logo: null,
            organization_background: null,
            default_password: false,
            _cn: null,
            dp: {
                user_id: null,
                old_password: null,
                new_password: null,
                new_confirm_password: null,
            }
        }
    },
    computed: {
        isValidPassword () { return !this.$v.dp.$invalid },
        isDirtyPassword () { return this.$v.dp.$anyDirty },

        data_loaded : function() {
            return this.$store.getters.getLoaded;
        }
    },
    validations: { // FORM Validation (Vue)
        dp: {
            old_password: {required},
            new_password: {required, minLength: minLength(8), strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/) },
            new_confirm_password: {required, sameAsPassword: sameAs('new_password') },
        },
    },
    created() {
        document.title = "Avante Transition Login"
        // Clear Local Storage Data before login.
        this.$store.commit("CLEAR_STATE_LOCAL_STORAGE");
        this.getOrganization()
    },
    methods: {
        getOrganization: function () {
            axios.get(process.env.VUE_APP_BACKEND_URL+'/organization/'+this.$route.params.orgName, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.organization_logo = this.$backend_url+"/organization/images/logo/"+response.data.data.org_key
                    this.organization_background = this.$backend_url+"/organization/images/background_image/"+response.data.data.org_key
                    this.organization_key = response.data.data.org_key
                }
            })
        },
        validatePassword: function () {
            this.$v.dp.$touch()
        },
        validatePasswordInput: function() {
            if(
                !this.dp.old_password ||
                !this.dp.new_password ||
                !this.dp.new_confirm_password
                ) return false;
            return true;
        },
        checkIfValidPassword: function (fieldName) {
            const field = this.$v.dp[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        showPassword: function() {
            if(this.password_view) {
                this.password_text = 'text'
            } else {
                this.password_text = 'password'
            }
        },
        login: function () {
            let data = {
                id: this.$route.query.id,
                otp: this.$route.query.otp,
            }
            window.axios.defaults.headers.common['Organization-Name'] = this.$route.params.orgName
            window.axios.defaults.headers.common['Organization-Key'] = this.organization_key
            axios.post('/ais-drs/validate-otp', data, {validateStatus: () => true},
            )
            .then(response => {
                if(response.status == 200) {
                    this.$store.commit("LOGGED_USER", response.data);
                    this.$store.commit("SET_USER_DETAILS", response.data);
                    this.$store.commit("SET_ORGANIZATION_NAME", this.$route.params.orgName);
                    this.$store.commit("SET_ORGANIZATION_KEY", this.organization_key);
                    window.axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getUserToken;

                    //adding user_preference
                    let user_preference = response.data.user_preferences;
                    let isDark = user_preference.dark_mode == 0? false : true;
                    let documentRouting = user_preference.document_routing;
                    let formApplication = user_preference.form_application;
                    document.body.classList.remove('theme-dark', 'theme-light');
                    if (isDark == true) {
                        if(!this.$store.getters.getDarkMode) {
                            this.$store.commit('toggle', 'dm');
                        }
                    }
                    this.$store.commit("SET_USER_PREFERENCE", user_preference);
                    this.$store.commit('documentRoutingViews', documentRouting);
                    this.$store.commit('formApplicationViews', formApplication);
                    this.getUserNavigation();
                    this.getAppNavigation();
                    return;
                } else if (response.status == 300) {
                    if(response.data.message == 'Default Password') {
                        this.dp.user_id = response.data.data.user_id;
                        this._cn = response.data.data._cn;
                        this.default_password = true;
                    }
                }   
            })
        },
        changeDefaultPassword: function () {
            let data = {
                user_id: this.dp.user_id,
                old_password: this.dp.old_password,
                new_password: this.dp.new_password,
                new_confirm_password: this.dp.new_confirm_password,
                _cn: this._cn,
            };
            if (this.validatePasswordInput()) {
                axios.post(process.env.VUE_APP_BACKEND_URL+"/change-default-password", data, {validateStatus: () => true})
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: 'center',
                            icon: "success",
                            title: "Password updated.",
                            timer: 3000,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        }).then(() => {
                            this.default_password = false;
                        })
                    }
                })
            } else {
                this.$swal({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                })
            }
        }
    },
    watch: {
        default_password: function (value) {
            if(value) {
                this.validatePassword();
            } else {
                if(this.$route.query.id != undefined || this.$route.query.otp != undefined) {
                    this.login();
                }
            }
        },
        organization_key: function(value) {
            if(value) {
                if(this.$route.query.id != undefined || this.$route.query.otp != undefined) {
                    this.login();
                }
            }
        },
        data_loaded: function (value) {
            if(value) {
                if(this.$route.query.next_url) {
                    this.$router.push({ path: `/${this.$route.params.orgName}${this.$route.query.next_url}`});
                } else {
                    this.$router.push({ path: `/${this.$route.params.orgName}/dashboard` });
                }
            }
        }
    }
}
</script>